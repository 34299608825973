body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* gallery images style */


.hover_img {
  /* width: 100%; */
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor : pointer;
}

.hover_img .overlay {

  width: 100%;
  height: 46%;
  position: absolute;
  overflow: hidden;
  top: -57px;
  left: 0;

}


.hover_img div.info {
  display: inline-block;
  text-decoration: none;
  padding: 7px 14px;
  text-transform: uppercase;
  color: #fff;
  margin: 50px 0 0 0;
  background-color: transparent;
}




  .ehover1 .overlay {
    opacity: 1;
    /* border-radius: 10px; */
    background-color: rgba(0,0,0, 0.12);
    box-shadow: 0px  0px 5px 0px #00000040;

  }
  
  
