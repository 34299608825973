@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@800&family=Karla&display=swap');






ul li {
    list-style-type: none;
}

body,
h1,
h2,
h3,
p,
li,
a,
button {
    font-family: 'Karla', sans-serif;
}

ul li a,
span a {
    text-decoration: none;
}

h1 {
    font-family: 'Dosis', sans-serif;
    text-align: center;
}


@media (min-width: 1200px) {
    .container {
        max-width: 90%;
    }
}


#main {
    /* padding-top: 86px; */
    padding-top: 72px;
    background-color: #f8f8f8;
}

.header {
    position: fixed;
    background-color: #103A3A;
    background-color: #103A3A;
    z-index: 1001;
    width: 100%;
}
.header .row {
    align-items: center;
}

.header .logo img {
    width: 280px;
}

.header .navbar {
    padding: 0px;
}

.header .navbar ul li {
    padding: 0px 20px;
}

.header .navbar ul li a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    /* padding: 35px 0px; */
    padding: 28px 0px;
}

.header .navbar ul li a:hover {
    color: #ffb900;
}

.header .navbar span.sub-arrow {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 5px;
    vertical-align: middle;
    border-top: 5px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.header-right {
    text-align: end;
}

.btn-blue {
    text-decoration: none;
    color: black;
    /* padding: 16px 32px; */
    padding: 10px 20px;
    background-color: #ffb900;
    font-weight: bold;
    margin-right: 8px;
    border-radius: 6px;
}

.btn-blue:hover {
    background-color: #d39c04;
    color: black;
}

.header-right span,
.header-right span a {
    color: #ffffff;
}

.header-right span a:hover {
    color: #009cff;
}

a.with-sub:hover+ul.sub-menu {
    display: block;
}

ul.sub-menu:hover {
    display: block;
}


.header .sm-menu .sub-menu li {
    padding: 0px;
    border-bottom: 1px solid grey;
}

.header .sm-menu .sub-menu li a {
    color: black;
    padding: 20px 15px;
}

.header .sm-menu .sub-menu li a:hover {
    color: grey;
}

.header .sm-menu ul.sub-menu {
    background-color: white;
    border-top: 4px solid #009cff;
    box-shadow: 0px 0px 2px grey;
}


.header .nav-mob {
    display: none;
}

.header .nav-mob button {
    font-size: 18px;
    background-color: transparent;
    border: none;
}

.header .nav-mob button span i {
    color: white;
}

.header .nav-mob ul li {
    padding: 15px 0px;
    border-bottom: 1px solid #ffb900;
}

.header .nav-mob ul li a {
    color: black;
    font-size: 18px;
    text-decoration: none;
    text-transform: uppercase;
}

.header .nav-mob ul li a:hover {
    color: #ffb900;
}


.header .offcanvas {
    background-color: white;
    width: 86%;
}

.header .offcanvas button {
    color: white !important;
}

.header .offcanvas-header {
    justify-content: space-between;
    background-color: #103A3A;
}

.header .offcanvas-header h2 {
    color: white;
    font-size: 20px;
    margin: 0px;
}

.header .offcanvas-header img {
    width: 150px;
}



.banner {
    /* background-color: #e79527; */
    background-image: url("./Assets/Images/qqqq.jpg");
    height: 60vh;
    background-size: 100%;
    background-repeat: no-repeat;
}

.banner .container {
    display: flex;
    align-items: self-start;
}

.banner h1 {
    color: white;
    font-size: 5.5rem;
    padding-top: 2rem;
    text-align: left;
}

.search-bar {
    background-color: #2e4b4f;
    padding: 21px 19px;
    position: relative;
    bottom: 87px;
}

.search-bar select {
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    appearance: none;
    color: grey;
    margin-bottom: 8px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='grey' stroke='grey' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

.search-bar button {
    padding: 0px 12px;
    height: 40px;
    background-color: #ffb900;
    color: white;
    font-weight: bold;
    font-size: 14px;
    border: none;
    margin-right: 8px;
}

.search-bar button:hover {
    background-color: #d39c04;
}

.top-categories {
    background-color: #FFFFFF;
    font-size: 20px;
    text-align: center;
    margin-bottom: 60px;
    position: relative; 
}

.line {
    position: absolute;
    top: 50%; 
    width: 28%; 
    height: 2px; 
    background-color: #E9EAEC; 
}

.line:first-child {
    left: 105px;
}

.line:last-child {
    right: 105px;
}
@media (max-width: 576px) {
    .line{
        display: none;
    }
    .price-sec {
        flex-direction: row;
    }
    .price-social-container {
        display: flex;
        justify-content: space-between;
        align-items: end;
    }
   
    .header .logo img {
        width: 240px;
    }
    
    
}

@media (max-width: 992px) {
    .line{
        width: 200px
    }
    

    .line:first-child{
        left: 10px
    }

    .line:last-child{
        right: 10px
    }
}
@media only screen and (max-width: 1024px){
    .banner{
        height: 31vh;
    }
}
.category-text {
    margin-top: -16px;
    padding-top: 1px;
    margin-bottom: -4rem;
    font-weight: bold;
}

.scrollmenu {
    overflow: auto;
    white-space:  nowrap;
}
.scrollmenu.row {
    display: inline-block;
}
.Scrollmenu .row .col-6 {
    cursor: pointer;
}

.newpet-sec {
    text-align: center;
    margin-bottom: 60px;
    margin-top: 15px;
}
.heading-home {
    font-size: 36px;
}

.card-sec {
    margin-top: 40px;
}
.view-all-card{
    padding-bottom: 5px;
}

.newpet-sec .card-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 25px;
}

.newpet-sec .card-header .icons i {
    color: #888f99;
    padding-left: 8px;
}

.card {
    text-align: left;
    margin-bottom: 30px;
    box-shadow: 0px 0px 10px lightgray;
}

.card h2 {
    font-size: 15px;
    font-weight: bold;
}

.card h3 {
    color: #00C194;
    font-weight: bold;
    font-size: 22px;
}

.listing-page-card{
    height: 200px !important;
}

.card img {
    height: 203px;
    object-fit: cover;
}

.card-body span {
    color: grey;
}

.card-body span i {
    margin-right: 10px;
}

.card-footer {
    display: flex;
    justify-content: space-between;
}

.card-footer li {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding-right: 2px;
    line-height: 1px;
}

.card-footer p {
    margin: 0em;
    color: grey;
    padding-left: 1px;
    line-height: 1;
}
.card-footer svg{
    vertical-align: middle;
    margin-right: 0.2em;
}

.categories {
    background-color: #F8F8F8;
    text-align: center;
    padding: 35px 0px;
}
.stats {
    background-color: #2c394c;
    text-align: center;
    padding: 80px 0px;
}

.stats img {
    width: 83px;
}
.categories img {
    /* width: 128px; */
    width: 112px;
    padding-bottom: 8px;
}
.cat-text{

    color: black;
    text-decoration: none !important;
}


.cat-text h3{
    font-size: 18px;
}

.cat-text:hover {
    color: black;
    text-decoration: none;
}


.stats h3 {
    font-family: 'Dosis', sans-serif;
    color: #ffb900;
    font-size: 3rem;
}

.stats p {
    color: white;
}

.collage-sec {
    padding: 25px 0px;
}

.collage-sec .row {
    padding-top: 40px;
}

.collage-sec h3 {
    font-family: 'Dosis', sans-serif;
    font-size: 23px;
    background-color: #103A3A;
    opacity: 0.9;
    bottom: 60px;
    color: white;
    padding: 12px 24px;
    margin: 0px;
}

.img-full img {
    width: 100%;
    /* height: 500px; */
}

.img-half img {
    width: 100%;
    height: 210px;
}

.multi-img {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.featured {
    padding-bottom: 100px;
    text-align: center;
}

.featured p {
    margin-bottom: 3rem;
}

.featured .prop {
    margin-top: 20px;
    border-top: 1px solid lightgrey;
}

.featured .prop span {
    display: block;
    padding-top: 20px;
}

.featured .prop span i {
    color: #009cff;
}

.featured .card img {
    width: 100%;
    height: 250px;
    object-fit: fill;
}

.featured .card-body {
    padding: 1.5rem 0.5rem;
}

.carousel-straight-line {
    width: 50px;
    height: 3px;
    background: grey;
}

.active-straight-line {
    background-color: #009cff
}

.icon-theme-color {
    color: #009cff;
}


.footer {
    background-color: #103A3A;
    color: #FFFFFF;
    padding-top: 70px;
}

.footer h2 {
    color: #D39C04;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 22px;
}

.footer ul {
    padding: 0px;
}

.footer ul li {
    padding: 8px 0px;
}

.footer ul li a {
    color: #FFFFFF;
}

.footer a:hover {
    color: #009cff;
}

.footer .quick-links {
    display: flex;
}

.footer form {
    display: flex;
}

.footer form input {
    width: 100%;
    padding: 15px;
    color: grey;
    background-color: #ffffff;
    border: none;
}

.footer form button {
    background-color: #D39C04;
    padding: 10px 20px;
    border: none;
    color: white;
}

.footer .social-links {
    display: flex;
    margin-top: 5px;
}

.footer .social-links svg {
    padding: 0px 10px;
    fill: lightslategray;
}

.footer .social-links svg:hover {
    fill: #009cff;
}

.footer .news-letter {
    text-align: left;
}

.footer .copy-right {
    border-top: 1px solid lightslategray;
    padding: 20px 0px;
    margin-top: 30px;
}

.footer .copy-right p {
    margin: 0px;
}

@media (min-width: 768px) {

    .search-bar select {
        margin-bottom: 0px;
    }

    .pagination-sec{
        overflow: hidden;
    }
    .pagination-sec li {
        margin: 1px; /* Reduce margin for smaller screens */
    }

    .pagination .page-link {
        padding: 8px 15px !important;
        font-size: 15px !important;
    }
    .header .logo img {
        width: 240px;
    }
    
}

@media only screen and (max-width: 992px) {

    .header .navbar,
    .header-right {
        display: none;
    }

    .header .nav-mob {
        display: flex;
        justify-content: end;
    }

    .btn-blue {
        width: 100%;
        text-align: center;
    }



    .img-half,
    .img-full {
        margin: 10px 0px;
    }

    .collage-sec img {
        height: 220px !important;
    }

    .card-footer li {
        padding-right: 20px;
    }

    .footer .social-links {
        justify-content: center;
    }

    .footer .news-letter,
    .footer .copy-right {
        text-align: center;
    }

    .reg .reg-form{padding: 30px 10px;}


    .member-sec{padding:20px 10px;}
    .tab-content h2{text-align: center; padding-bottom: 20px;}
    .sec-content{margin: 0; border: none;}
    .dashboard .nav-link{padding: 10px; font-size: 16px;}
    .sec-content .content-footer{position:static; margin: 0; width: 100%;}
    .sec-content .content-header{display: block; margin-bottom: 10px; padding-left: 0px;}
    .sec-content img{width: 100%;}
    .dashboard .nav{justify-content: center;}
    .dashboard .nav-item{border: none;}


    /* t work */
    .banner{
        /* height: 37vh; */
        height: 28vh;
    }

     /* t work */
     #main{
        padding-top: 56px;
    }

}


.pointer-cursor {
    cursor: pointer;
}

li.react-multi-carousel-item.react-multi-carousel-item--active {
    opacity: 1;
}


.react-multi-carousel-item {
    /* transform-style: preserve-3d;
    backface-visibility: hidden; */
    opacity: 0.5;
}

/* Product Page */

.breadcrums ul {
    /* display: flex; */
    /* form tay */
    display: none;
    margin: 10px 0px;
    font-size: 15px;
    padding: 0;
}

.breadcrums li a {
    color: #009cff;
}

.breadcrums span {
    color: grey;
    padding: 0px 10px;
}

.product-detail {
    margin-bottom: 60px;
}

.product-detail h1 {
    text-align: left;
    font-size: 28px;
    padding-bottom: 14px;
    padding-top: 25px;

}

.product-detail h3 {
    font-weight: bold;
    font-size: 21px;
    margin: 20px 0px;
}

.heading-sec {
    margin-bottom: -10px;
}

.heading-sec .loc span {
    color: gray;
    padding-right: 10px;
}

.heading-sec .loc span i {
    margin-right: 10px;
}

.heading-sec .loc span:nth-child(2) {
    padding: 0px 10px;
}

.heading-sec .price-sec {
    text-align: right;
}

.heading-sec .price-sec h3 {
    font-size: 28px;
    font-weight: bold;
    color: black;
    padding-top: 8px;
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.callNow-btn{
    flex-grow: 1;
    text-decoration: none;
    color: black;
    padding: 6px 20px;
    background-color: aliceblue;
    border-color: aliceblue;
    font-weight: bold;
    border-radius: 6px;
    height: 40px;
    border-width: thin;
    font-size: 13px;
}
.whatsapp-btn{
    flex-grow: 1;
    text-decoration: none;
    color: black;
    padding: 4px 6px;
    background-color: mediumseagreen;
    border-color: mediumseagreen;
    font-weight: bold;
    border-radius: 6px;
    height: 40px;
   border-width: thin;
    font-size: 13px;
}



/* .carousel.carousel-slider .control-arrow { */

.control-arrow {
    position: absolute !important;
    top: 46% !important;
    background: #ffffff !important;
    /* border-color: #009cff !important;
    color-scheme:  #009cff !important; */
    color: #00c194 !important;

    opacity: .6 !important;
    height: 44px !important;

    opacity: 0.6 !important;
    padding: 0px 16px 4px 6px !important;
    /* padding: 0px 12px 8px 12px!important; */
    border-radius: 45px !important;
}

.prod-slider {
    padding-bottom: 15px;
}

.prod-slider-item img {
    /* width: 100%; */
    height: 500px;
    /* background-color: black; */
}

.carousel-slider{
    background-color: black !important;
}


svg.svg-inline--fa.fa-greater-than.fa-2xs, svg.svg-inline--fa.fa-less-than.fa-2xs {
    vertical-align: 0em !important;
}

/* .prod-slider .owl-thumbs { white-space: nowrap; overflow: auto; padding: 10px 0px; display: flex; justify-content: space-between;}
.prod-slider .owl-thumbs button{margin-right: 6px; padding: 0;}
.prod-slider .owl-thumbs button>img { width: 164px;height: 95px; border-radius: 4px; }
.prod-slider .owl-thumb-item{border: none;}
.prod-slider .owl-carousel .owl-nav button.owl-next, .prod-slider .owl-carousel .owl-nav button.owl-prev {position: absolute; top: 40%; background: white; opacity: 0.6; padding: 10px 17px!important; border-radius: 30px; }
.prod-slider .owl-carousel .owl-nav i{color: #00c194;}
.prod-slider .owl-carousel button.owl-next{right: 20px;}
.prod-slider .owl-carousel button.owl-prev{left: 20px;} */


.carousel .thumbs {
    display: flex; justify-content : flex-start;
}

.carousel .thumb {
    width: 20% !important;  height: 150px !important; margin-right: 0px !important; transition : none !important;
}

.carousel .thumbs-wrapper {
    margin: 10px 0 0px 0px !important;
}

li.thumb img {
    width: 100% !important; height: 70% !important; border-radius: 4px !important;
}

ul.thumbs.animated {
    padding-left: 0rem !important;
}

li.thumb.selected > img, li.thumb:hover > img{
     border: 3px solid #00000035 !important; border-radius: 4px !important;     
}

.carousel .thumb.selected, .carousel .thumb:focus, .carousel .thumb:hover {
    border: none !important;
}

.overview-sec {
    background-color: white; box-shadow: 0px 0px 3px lightgrey; padding-top: 2px
}

.overview-sec .content {
    padding: 0px 30px 12px 30px; box-shadow: 0px 1px 1px lightgrey;
}

.overview-sec .content p,
.overview-sec .content li {
    color: grey;
}

.overview-sec .content p {
    line-height: 1;
}

.overview-sec .prop ul,
.overview-sec .prop li {
    display: flex;  justify-content: space-between;  padding: 0;
}

.overview-sec .prop-icon i {
    color: #00c194;
    display: flex;
    font-size: 24px;
    box-shadow: 0px 0px 3px lightgrey;
    border-radius: 2px;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

/* .overview-sec .prop-content {
    width: 50px;
} */

.overview-sec .prop-content p {
    margin: 0;
}

.overview-sec .prop-content p:first-child {
    font-weight: bold;
    color: black;
}

.overview-sec .feature-list {
    padding: 0;
    margin: 0;
}

.overview-sec .feature-list li {
    padding-bottom: 10px;
}



.overview-sec .feature-list li svg.svg-inline--fa.fa-circle-xmark {
    color: #ab0603;
    padding-right: 10px;
}

 
.overview-sec .feature-list li svg.svg-inline--fa.fa-circle-check {
    color: #00c194;
    padding-right: 10px;
}


.right-panel , .side-panel {
    background-color: white;
    padding: 10px 25px;
    box-shadow: 0 4px 18px 0 rgba(194, 200, 213, .3)
}

.contact-sec .contact-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-sec img {
    width: 100%;
    border-radius: 55px;
    padding: 5px;
    border: 1px solid grey;
}

.contact-sec .contact-detail h3 {
    font-size: 20px;
    color: #1351a4;
    margin: 10px;
}

.contact-sec .contact-detail i {
    padding-right: 5px;
}

.contact-sec .contact-detail p {
    margin: 0;
}

.contact-sec .fa-whatsapp {
    color: #25D366;
}

.contact-sec .contact-form {
    padding: 23px 0px 20px;
}

.contact-sec .contact-form input {
    height: 48px;
}

.contact-form .btn-green {
    background-color: #00c194;
    color: white;
    font-family: 'Karla';
    font-size: 13px;
    font-weight: bold;
    width: 100%;
    border: none;
    height: 48px;
    border-radius: 3px;
    box-shadow: 0 7px 18px 0 rgba(0, 193, 148, .32);
}

.contact-sec .btn-green:hover {
    background-color: #00a376;
}

.listing-sec {
    margin-top: 20px;
}

.listing-sec i {
    padding-right: 3px;
    font-size: 13px;
}

.listing-sec .active {
    margin-bottom: 28px;
}

.listing-sec .active img {
    width: 100%;
    height: 175px;
    margin-bottom: 15px;
}

.listing-sec .active h2 {
    color: #00c194;
    margin: 10px 0px;
    font-size: 20px;
    font-weight: bold;
}

.listing-sec .active h3 {
    margin: 5px 0px;
}

.listing-sec .active h3 a {
    font-size: 18px;
    color: black;
    text-decoration: none;
}

.listing-sec .active p {
    color: grey;
    margin: 0px;
}

.listing-sec .listing {
    border-top: 1px solid lightgrey;
    padding: 20px 0px;
}

.listing-sec .listing h2 {
    color: #00c194;  margin: 0px; font-size: 18px;  font-weight: bold;
}

.listing-sec .listing h3 {
    margin: 5px 0px;
}

.listing-sec .listing h3 a {
    font-size: 16px;margin: 5px 0px;color: black;text-decoration: none;
}

.listing-sec .listing p {   color: grey;margin: 0px;}

.listing-sec .listing img {width: 115%; height: 84px; border-radius: 3px;}

.listing-sec .active a:hover, .listing-sec .listing a:hover { color: #00c194;}




@media only screen and (max-width: 992px) {


    
    .heading-sec {margin-bottom: 10px;}

    .overview-sec .prop ul {  flex-wrap: wrap;}

    .overview-sec .prop li { margin-bottom: 30px; }
    /* .prod-slider .owl-thumbs button>img {width: 80px; height: 60px;} */

    .prod-slider-item img {
        width: 100%; height: 350px;
    }
    
    .contact-sec { margin-top: 20px;}

    .heading-sec .price-sec { text-align: left;   }

    .heading-sec .loc span:last-child { display: block; }


}


/* Listing page */


.range-slider{padding:10px 0px;}
.range-slider .tags{display: flex; justify-content: space-between; color: grey;}
.range-slider .tags .price{color: #00c194; font-weight: bold;}
.range-slider input[type="range"] {width:100%; overflow: hidden; height: 8px; -webkit-appearance: none; background-color: #00c194; border-radius: 10px;}
.range-slider input[type="range"]::-webkit-slider-thumb{ -webkit-appearance: none; border: 3px solid green; border-radius: 20px;  height: 20px; width: 20px; background-color: white; z-index: 100;}

.amen span{color: #00c194; padding-right: 20px; }
.amen span a{color: #00c194;text-decoration: none;}
.amen span i{padding: 20px; border: 1px solid lightgrey; border-radius: 5px;}

.adv-search{ padding-bottom:20px; margin-top: 20px;}
.adv-search .search-form button{font-family: 'Karla'; font-size: 14px; font-weight: bold; padding: 10px 16px; color: #00c194; border: 3px solid #00c194; background-color: white; border-radius: 5px; } 
.adv-search .search-form button:hover{ color: white; background-color: #00c194;}
.adv-search .search-form button i{padding-right: 3px;}
.adv-search .form-control{ color: grey;}

.results{margin-bottom: 80px;}
.results .product-sec{margin-top: 20px;}
.results h5{ padding-top: 20px;}
.sort {display: flex; align-items: center; justify-content: end;}
.sort span{ padding-right: 10px;}
.sort .icons{margin: 0; padding-left: 30px;}
.sort select{color: grey;}
.pagination-sec{display: flex; justify-content: center; padding-top: 30px; flex-wrap: wrap; overflow: hidden; margin: 0; padding: 0;}
.pagination-sec li.page-item.disabled a {color: grey;} 
.pagination-sec li{margin-left:5px;}
.pagination-sec li a{color: #00c194; font-weight: bold; font-size: 18px;}
.pagination-sec .page-link{padding: 10px 20px;}
.pagination-sec .page-link:hover{background-color: #00c194; color: white;}


.page-item {
    margin-left: 5px !important;
}

.pagination .page-link {

    padding: 10px 20px !important;
    color: #00c194 !important;
    font-weight: bold !important;
    font-size: 18px !important;
}

.active>.page-link, .page-link.active {
    z-index: 3;
    color: white !important;
    background-color:  #00c194 !important;
    border-color:  #00c194 !important;;
}



.fp-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #f8f8f8ad;

}

.fp-container .fp-loader {
    top: 40%;
    left: 62%;
    position: absolute;
}

.fp-container .fp-loaderCenter {
    top: 40%;
    left: 50%;
    position: absolute;
}


@media only screen and (max-width: 992px){
    .heading-sec{margin-bottom: 10px;}
    .overview-sec .prop ul{flex-wrap: wrap;}
    .overview-sec .prop li{margin-bottom:30px;}
    /* .prod-slider .owl-thumbs button>img {width: 80px; height: 60px;} */

    .contact-sec {margin-top:20px;}
    .heading-sec .price-sec{text-align: left;}
    .heading-sec .loc span:last-child{display: block;}
    .heading-sec .icons{justify-content: left;}
    .adv-search form button{width: 100%; margin-bottom: 10px;}
    .sort { justify-content: space-between;}


    .add-product-form{ padding: 0px 20px 40px;}
    .add-product-form button{width: 100%;}
    .add-product-form .pet-features {display: block; margin-left: 30px !important;  }
    .add-product-form .form-check{padding: 0px;margin-bottom: 5px;}
    .product-info {display: grid; grid-template-columns: auto;} 
    .owner-detail .owner-info{display: block;}
    .owner-detail .form-control{ width: 100%; margin:0px; margin-bottom: 10px;}
    .add-product-form .form-check.radio{ justify-content: space-between; padding: 15px 0px;}
    .add-product-form span h4{display: block; margin: 0; }

}





.dropdown-toggle::after {
    display: none !important;
 }

 .ShareDropDown {
    display: inline !important;
    margin-right: -10px !important;
}

.ShareDropDown .dropdown-menu.show {
    display: flex !important;
    justify-content: space-evenly !important;
}
 .ShareDropDown .btn-success {
    --bs-btn-color: #ffffff !important;
    --bs-btn-bg: #ffffff !important;
    --bs-btn-border-color: #ffffff !important; 
    --bs-btn-hover-color: #ffffff !important;
    --bs-btn-hover-bg: #ffffff !important;
    --bs-btn-hover-border-color: #ffffff !important;
    /* --bs-btn-focus-shadow-rgb: 60,153,110; */
    --bs-btn-active-color: #ffffff !important;
    --bs-btn-active-bg: #ffffff !important;
    --bs-btn-active-border-color: #ffffff !important;
    /* --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); */
    --bs-btn-disabled-color: #ffffff !important;
    --bs-btn-disabled-bg: #ffffff !important;
    --bs-btn-disabled-border-color: #ffffff !important;
}


.icon-inactive{
    color: #404040 !important;
}

.icon-active{
    color: #f72a2a !important;
}

.icon-active2{
    color: #55ca1b !important;
}

li.social-share button {
    background-color: #ffffff;
    margin-right: 10px !important;

    border-radius: 0 !important;
    border-color: #00000018;
 }

 li.social-share .button-icon svg{

    color: #198754 !important;
 }

 li.social-share .button-icon:hover,  li.social-share .button-icon:hover > svg {
    background-color: #00a376 !important;
    color: white !important;

}


  


.page-header.icons{display: flex; justify-content: end; padding: 0;}
.page-header.icons li .svg{ border: 1px solid #e0e5ee; padding: 10px 14px; border-right: none; background-color: white; }
.page-header.icons li:last-child .svg{border-right: 1px solid lightgrey;}
.page-header.icons li .svg{color: #00a376;}
.page-header.icons li .svg:hover{background-color: #00a376; color: white;}

.page-header.icons li .svg-heart{ background-color: #00a376; padding: 10px 14px;  border-right: none; color: white; }

.page-header.icons li .svg-heart:hover{
    border: 1px solid #e0e5ee;  background-color: white;
    color: #00a376;
    }


/* Add Pets Page */
.add-product-form{padding: 50px 35px; background-color: white;}
.add-product-form .section-heading{font-size: 26px; padding-bottom: 20px;}
.add-product-form h4{font-size: 20px; font-weight: bold;}
.product-info {display: grid; grid-template-columns: auto auto auto; column-gap:40px; row-gap:30px; padding-bottom: 30px;}
.product-info select,
.product-info input,
.form-item-inner,
.owner-detail .input-1,
.owner-detail  .css-1fdsijx-ValueContainer,
 .owner-detail select{ height: 60px; color: grey;}
 .width-class {width: 350px;}

.add-product-form .pet-features{ display: flex; padding-bottom: 30px; margin-left: 30px !important;} 
.add-product-form .form-check{display: flex; align-items: center; margin-right: 20px;}
.add-product-form .form-check:first-of-type{padding: 0px;}
.add-product-form input[type="checkbox"] { width: 30px; height: 30px; margin-right: 10px; border: 1px solid #ced4da; border-radius: 3px; appearance: none;}
.add-product-form input[type="checkbox"]:checked::after {content: "\2713"; display: flex; justify-content: center; align-items: center; background-color: #00c194; color: white; height: 30px; }
.add-product-form input[type="radio"]{ width: 25px; height: 25px; margin-right: 10px; border: 1px solid #ced4da; border-radius: 20px; appearance: none;}
.add-product-form .form-check.radio label{ padding-right:20px;}
.add-product-form input[type="radio"]:checked { border: 8px solid #00c194; }
.add-product-form .row.pet-features{ padding-bottom: 80px;}
.add-product-form textarea{width: 100%; margin-bottom: 50px; border:1px solid #ced4da; }
.add-product-form span h4{display: inline-flex;}
.add-product-form .uploadfile{ border:1px solid #ced4da; width: 100%; height: 200px; display: flex; justify-content: center; align-items: center; margin-top:10px ;}
.add-product-form .uploadfile label{ }
.add-product-form input[type="file"]{ appearance: none;}
.owner-detail{padding-top: 50px;}
.owner-detail .form-control{  width: 300px; margin-right: 20px;}
.owner-detail .city-select {border: 0 !important; padding: 0px !important; }
.owner-detail .owner-info{display: flex; flex-wrap: wrap;}
.add-product-form .submit-button{padding: 20px 30px; background-color: #00c194;  font-weight: bold; color: white; margin-top: 30px; border: none; border-radius: 5px;}
#pet-img{opacity: 0; position: absolute;}


.form-large input[type="text"],.form-large input[type="password"],.form-large input[type="tel"],.form-large select{  height: 37px; color: grey;}
.form-large input[type="checkbox"]{ width: 30px; height: 30px; margin-right: 10px; border: 1px solid #ced4da; border-radius: 3px; appearance: none;}
.form-large input[type="checkbox"]:checked::after {content: "\2713"; display: flex; justify-content: center; align-items: center; background-color: #00c194; color: white; height: 30px; }
.form-large input[type="radio"]{ width: 25px; height: 25px; margin-right: 10px; border: 1px solid #ced4da; border-radius: 20px; appearance: none;}
.form-large input[type="radio"]:checked { border: 8px solid #00c194; }
.form-large button{font-family: "Karla"; padding: 13px 30px; background-color: #00c194;  font-weight: bold; color: white; margin-top: 30px; border: none; border-radius: 5px;}


/* Registration */
.reg {padding:10px 0px 60px; }
.reg img{width: 100%; height: 100%;}
.col.col-lg-6{padding: 0px;}
.reg h2{text-align: center; color: #00c194; font-weight: bold; margin-bottom: 40px;}
.reg .reg-form{padding: 50px 80px; background-color: white; box-shadow:0px 0px 2px lightgray;}
.reg-form .form-item{margin-bottom: 20px;}
.reg-form .form-check{display: flex; justify-content: center; align-items: center;}
.reg-form  button{width: 100%;}
.reg-form p{text-align: center; font-weight: bold; padding-top: 30px;}

/* Shop-listing */

.shop-listing .card-header{padding: 0px;}
.shop-listing .card-header img{width: 100%;}
.shop-listing .card-header .icons{position: absolute; right: 20px; top: 15px;}
.shop-listing .card-header .icons button{background-color: transparent; border: none;}
.shop-listing .card-header .icons i{color: red;}
.shop-listing .card-body h2{ font-size: 18px; color: #1351a4;}
.shop-listing .card-body h3{ font-size: 18px; color: #38475c; margin: 0px;}
.shop-listing .card-footer{padding: 0px;}
.shop-listing .card-footer button{background-color: #103A3A; color: white; width: 100%; border: none; padding: 9px 0px; border-bottom-left-radius:4px; border-bottom-right-radius:4px;}



/* Dashboard */ 
.account-page{padding-bottom: 40px;}
.member-sec{background-color: white; box-shadow:  0px 0px 4px lightgrey;margin: 20px 0px; padding: 30px 50px;}
.member-info{display: flex; align-items: center;}
.member-info img{width: 91px; height: 100px; border-radius: 50%; background-color: grey; margin-right:30px; border: 3px solid lightgray;}
.member-info h2{color: #1351a4; font-size: 25px; font-weight: bold; margin: 0;}
.member-info p{margin-bottom: 5px;}
.member-info li{ list-style-type: none;}
.member-info li span{color: #028be0; padding: 0px 3px; }
.member-info li a{ font-size: 14px; text-decoration: none;}

.dashboard{background-color: white; box-shadow: 0px 0px 4px lightgrey; border-radius: 5px;}
.dashboard .nav{ box-shadow: 0px 0px 4px lightgrey;}
.dashboard .nav-item{border-bottom: 2px solid lightgray;}
.dashboard .nav-item i{padding-right: 3px;}
.dashboard .nav-item:last-child{border-bottom: none}
.dashboard .nav-link{ color: grey; padding: 20px px; font-size: 22px; display: flex; align-items: center;}
.dashboard .nav-link.active{background-color: #ffb900; color: white;}

.tab-content{padding-top: 30px;}
.tab-content h2{font-weight: bold; font-size: 35px;}
.sec-content{margin: 30px 30px 30px 0px; border: 1px solid lightgray; border-radius: 4px; padding:15px; position: relative;}
.sec-content img{ width: 150px; height: 150px; background-color: grey; }
.sec-content .content-header{display: flex; justify-content: space-between; padding-left: 20px;}
.sec-content .content-header h3{ font-size: 20px; font-weight: bold; margin: 0;}
.sec-content .content-header p{font-size: 15px;}
.sec-content .content-header button, table tr td button, .dashboard-theme-button {color: white; background-color: #00c194; border: none; border-radius: 4px; font-size: 13px; padding: 3px 20px; margin-right: 3px;}
.sec-content .content-footer{position: absolute; bottom:0px; margin-bottom:20px; margin-left: 20px; width: 78%;}
.sec-content .content-footer ul{display: flex; justify-content: space-between; margin: 0; background-color: #f6f6f6; padding: 5px 10px; border-radius: 4px;}
.sec-content .content-footer ul li{color: grey;}
.sec-content .content-footer ul li i{padding-right: 2px;}



@media screen and (max-width: 540px) {

    .carousel .thumb {
        height: 100px !important;
     }
    .prod-slider-item img {
        width: 100%; height: 250px;
    }

    .fp-container .fp-loader {
        top: 30%;
        left: 45%;
        /* right: ; */
        z-index: 1000;
        position: absolute;
    }

    .fp-container .fp-loaderCenter {
        top: 30%;
        /* left: 50%; */
        /* right: ; */
        z-index: 1000;
        position: absolute;
    }

    /* t work */
    .banner{
        height: 27vh;
    }


        /* t work */
        .banner h1{
            font-size: 30px;
            text-align: center;
        }
        .search-bar {
            bottom: 78px;
        }

    /* t work */
    #main{
        padding-top: 56px;
    }
    .pagination-sec{
        overflow: hidden;
    }
    .pagination-sec li {
        margin: 1px;
    }

    .pagination .page-link {
        padding: 3px 6px !important;
        font-size: 13px !important;
    }
    .home-title {
    font-size: 16px;
    text-align: center;
    }

    .banner {
        background-image: url("./Assets/Images/mmm.jpg");
        font-size: 45px;
        text-align: center;
    }

    .reg .reg-form{padding: 35px 30px; background-color: white; box-shadow:0px 0px 2px lightgray;}

    .add-product-form .pet-features{ display: block; padding-bottom: 30px; margin-left: 30px !important;} 
    .overview-sec .prop-content p:first-child{
        width: 50%;
    }
    

}

/* For Tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {


    .sm, .sm ul, .sm li{
        display:  grid !important;
    }

    .fp-container .fp-loader {
        top: 25%;
        left: 20%;
        /* right: ; */
        z-index: 1000;
        position: absolute;
    }

    .fp-container .fp-loaderCenter {
        top: 25%;
        /* left: 20%; */
        /* right: ; */
        z-index: 1000;
        position: absolute;
    }

    .listing-sec{
        display: none;
    }
    .banner h1 {
        font-size: 45px;
        text-align: center;
    }

    .search-bar button {
        padding: 0px 12px;
       height: 40px;
       background-color: #ffb900;
       color: white;
       font-weight: bold;
       font-size: 15px;
       border: none;
       margin-left: -12px;
    }

    .banner {
        background-image: url("./Assets/Images/mmm.jpg");
    }

    .add-product-form .pet-features{ display: block; padding-bottom: 30px; margin-left: 30px !important;}

}


@media (min-width: 768px){ 
     .search-bar select{margin-bottom: 0px;}
    .dashboard .nav{ flex-wrap: nowrap; flex-direction: column;}
}





/* SM MENU  */

@media (min-width: 768px) {



    /* Switch to desktop layout
    -----------------------------------------------
    These transform the menu tree from
    collapsible to desktop (navbar + dropdowns)
    -----------------------------------------------*/
    /* start... (it's not recommended editing these rules) */
    .sm-menu ul {
        position: absolute;
        width: 12em;
    }

    .sm-menu li {
        float: left;
    }

    .sm-menu.sm-rtl li {
        float: right;
    }

    .sm-menu ul li,
    .sm-mytheme.sm-rtl ul li,
    .sm-mytheme.sm-vertical li {
        float: none;
    }

    .sm-menu a {
        white-space: nowrap;
    }

    .sm-menu ul a,
    .sm-mytheme.sm-vertical a {
        white-space: normal;
    }

    .sm-menu .sm-nowrap>li>a,
    .sm-mytheme .sm-nowrap>li> :not(ul) a {
        white-space: nowrap;
    }

    /* ...end */


    /* Your rules to style the menu in desktop view here... */
}


/*  Image gallery styles */


.hover_img {
    /* width: 100%; */
    height: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor : pointer;
  }
  
  .hover_img .overlay {
  
    width: 100%;
    height: 49%;
    position: absolute;
    overflow: hidden;
    top: -62px;
    left: 0;
  
  }
  
  
  .hover_img div.info {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    text-transform: uppercase;
    color: #fff;
    margin: 50px 0 0 0;
    background-color: transparent;
  }
  
  
  
  
    .ehover1 .overlay {
      opacity: 1;
      /* border-radius: 10px; */
      background-color: rgba(0,0,0, 0.12);
      box-shadow: 0px  0px 5px 0px #00000040;
  
    }
    
    .react-horizontal-scrolling-menu--scroll-container {
        scrollbar-width: none;
    }

    .advance {
        color: #FFFFFF;
    }
    .newly-added-pet {
        font-size: 36px !important
    }

    .view-all-button {
        color: #00C194;
        font-weight: bold;
        text-decoration: none;
        font-size: large;
    }

    .react-multiple-carousel__arrow--right {
        right: 20px !important;
        top: 40px !important;
    }
    .react-multiple-carousel__arrow--left{
        left: 20px !important;
        top: 40px !important;
    }
    .react-multiple-carousel__arrow {
        min-width: 32px !important;
        min-height: 32px !important;
        /* background-color: #FFFFFF !important; */
    }

    .Home-Category {
        font-family: Dosis, sans-serif;
      }

      .btn-filter {
        font-size: 14px;
        font-weight: bold;
        padding: 8px 12px;
        color: white;
        background-color: #00c194;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      
      .filter-btn svg {
        margin-right: 5px;
      }
      
      .linkkk{
        text-decoration: none;
      }
      .linkkk h2{
        color: black
      }
    
      @media screen and (min-width: 768px) and (max-width: 992px) {

        .sm, .sm ul, .sm li{
            display:  grid !important;
        }

        .banner h1 {
            font-size:45px;
        }
        .add-product-form .pet-features{ display: block; padding-bottom: 30px; margin-left: 30px !important;} 

      }

      @media  (min-width: 992px) and (max-width: 1280px) {
        .banner h1 {
            font-size: 80px;
        }
        .header .navbar ul li {
            padding: 0px 9px;
        }
    }
    
    .owner-name {
        color: #4a4a4a; 
        font-size: 16px;
        margin-bottom: 0; 
        font-weight: 100;
      }
      
      .custom-link {
        color: black !important; 
        font-style: normal;
        font-size: 14px;
        margin-top: 4px; 
        font-weight: bold;
      
      }
     
      .custom-link a {
        text-decoration: none;
        color: black;
        
      }
      
      .contact-detail {
        display: flex;
        flex-direction: column;
      }
      
      
      /* Start Contact Us */
      .can-form {
        padding: 50px 80px;
        background-color: white;
        box-shadow: 0px 0px 2px lightgray ;
      }
    .can {padding:10px 0px 60px; }
    .can img{width: 100%; height: 100%;}
    .col.col-lg-6{padding: 0px;}
    .can h2{text-align: center; color: #00c194; font-weight: bold; margin-bottom: 40px;}
    .can .reg-form{padding: 50px 80px; background-color: white; box-shadow:0px 0px 2px lightgray;}
    .can-form .form-item{margin-bottom: 20px;}
    .can-form .form-check{display: flex; justify-content: center; align-items: center;}
    .can-form  button{width: 100%;}
    .can-form p{text-align: center; font-weight: bold; padding-top: 30px;}
      /*End  Contact Us */


      
   .cc{
    width: 94% !important;
   }

   .footer-logo{
    width: 80%; 
    height: auto;
   }

   .carousel .slide img{
    width: auto !important;
   }



   /* src/ShopOwnerCategories.css */
.shop-owner-categories {
    text-align: center;
  }

  .post-your-ad{
    background-color: #F4F4F4;
    /* padding-right: auto;
    padding-left: auto; */
  }

  .post-text {
    text-align: center;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
}

.post-category{
    text-align: start;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    font-family: cursive;

  }
  
  .ads-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .ads-category {
    flex: 0 1 30%; 
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .ads-category.selected {
    border-color: #3ED400;
    border-width: 2px;
  }
  
  .ads-category img {
    
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
  
  .nav-ads {
    margin-top: 20px;
  }
  
  .nav-ads .back,
  .nav-ads .next {
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
  }
  
  .nav-ads .next {
    background-color: green;
    color: white;
  }
  .Listing-detail{
    line-height: 1.6 !important;
  }